import {
    Box,
    Flex,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    Icon,
    useDisclosure
} from '@chakra-ui/react'
import Link from '../Sidebar/Link'
import LogoBox from '../Sidebar/LogoBox'
import dataLinks from '../Sidebar/dataLinks'
import Hamburguer from '../Icon/Hamburguer'

export default function SidebarMenu() {
    const { isOpen, onClose, onOpen } = useDisclosure()
    return (
        <Flex pr="4">
            <Icon
                as={Hamburguer}
                fill={'blackAlpha.600'}
                cursor="pointer"
                fontSize="2xl"
                aria-label="Open menu"
                onClick={onOpen}
                _hover={{ fill: 'blackAlpha.500' }}
                pt={{ base: '0', md: '1' }}
            ></Icon>
            <Box>
                <Drawer isOpen={isOpen} onClose={onClose} placement="left">
                    <DrawerOverlay />
                    <DrawerContent maxW={'fit-content'}>
                        <Box w="232px" bg="black" color="white" h="100vh">
                            <Flex>
                                <Flex direction="column" py={8} w="full">
                                    <LogoBox />
                                    <Box
                                        className="scroll-no-bar"
                                        overflow={'auto'}
                                        style={{
                                            maxHeight: 'calc(100dvh - 180px)'
                                        }}
                                    >
                                        {dataLinks.map(route => (
                                            <Link
                                                key={route.path}
                                                label={route.label}
                                                Icon={route.Icon}
                                                path={route.path}
                                            />
                                        ))}
                                    </Box>
                                </Flex>
                            </Flex>
                        </Box>
                    </DrawerContent>
                </Drawer>
            </Box>
        </Flex>
    )
}
